import React from  'react'
import "./topbar.css"
import Navbar from './Navbar'
// import Footer from '../images/Footer'

export default function Topbar(){
    return (
        <>
     <Navbar/>
     {/* <Footer/> */}
    </>
    )
}